import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const PaidReceipt = Loadable(lazy(() => import('./PaidReceipt')));
const PodSendToParty = Loadable(lazy(() => import('./PodSendToParty')));

const ReportRoutes = [
  { path: '/paidreceipt', element: <PaidReceipt /> },
  { path: '/podsendtoparty', element: <PodSendToParty /> },
];

export default ReportRoutes;
