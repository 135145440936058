import { BILL_DATA, BILL_LIST } from "../constant/constant";

export const billData = function (state = [], action) {
    switch (action.type) {
      case BILL_DATA:
        return action.payload;
      // case CLEAR_SINGLE_BILTY_DATA:
      //   return state
      default: {
        return state;
      }
    }
  };
export const billList = function (state = [], action) {
    switch (action.type) {
      case BILL_LIST:
        return action.payload;
      default: {
        return state;
      }
    }
  };