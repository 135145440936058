import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const CreateMemo = Loadable(lazy(() => import('./creatememo/CreateMemo')));
const MemoStatement = Loadable(lazy(() => import('./creatememo/MemoStatement')));
const ReceiveMemo = Loadable(lazy(() => import('./creatememo/ReceiveMemo')));
const ReceiveMemoBilties = Loadable(lazy(() => import('./creatememo/ReceiveMemoBilties')));
// const Download = Loadable(lazy(() => import('./addbilty/Download')));
// const Details = Loadable(lazy(() => import('./biltydetails/Details')));
// const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
// const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
// const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));

const memoRoutes = [
  { path: '/memo', element: <CreateMemo /> },
  { path: '/memolist', element: <ReceiveMemo /> },
  { path: '/receivememo', element: <ReceiveMemoBilties /> },
  { path: '/memoreport', element: <MemoStatement /> },

  //   { path: '/download', element: <Download /> },
  //   { path: '/details', element: <Details /> },
  //   { path: '/session/signin', element: <JwtLogin /> },
  //   { path: '/session/forgot-password', element: <ForgotPassword /> },
  //   { path: '/session/404', element: <NotFound /> },
];

export default memoRoutes;
