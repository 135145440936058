import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import { useSelector } from 'react-redux';
// import useSettings from 'app/hooks/useSettings';

const MatxTheme = ({ children }) => {
  const { settings } = useSettings();
  let activeTheme = { ...settings.themes[settings.activeTheme] };
  const loading = useSelector((state)=>state.loadingData.loading)
  return (
    <ThemeProvider theme={activeTheme}>
 {/* {loading && <LinearProgress />} */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
